.technologies-container {
    text-align: center;
    margin: 20px;
}

.row1 {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap */
    justify-content: center;
    /* Center items */
    gap: 10px;
    margin-top: 20px;
}

.second-row {
    margin-top: 30px;
    margin-bottom: 50px;
}

.tech-item {
    width: 120px;
    flex: 1;
}

.tech-item img {
    margin-top: 1.2rem;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.tech-head {
    font-size: 38px;
    font-weight: 600;
    font-family: Arial, sans-serif;
    margin-bottom: 20px;
}

.tech-subhead {
    font-size: 20px;
    margin-top: 15px;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
    .tech-item {
        width: 80px;
        /* Adjust item width for smaller screens */
    }

    .tech-item img {
        width: 60px;
        /* Adjust image size for smaller screens */
        height: 60px;
    }

    .tech-head {
        font-size: 28px;
        /* Adjust font size for smaller screens */
    }

    .tech-subhead {
        font-size: 16px;
        /* Adjust font size for smaller screens */
    }
}