/* .section-white{
    background: rgb(240, 240, 234);


}
.team-item{
    
    background: #ffffff;
    text-align: center;
    margin: 20px 0;
    padding: 50px 20px 40px 20px;
    border-radius: 8px 8px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition:all 0.25s ease-in-out ;
}
.team-img{
   
    max-width: 250px;
    height: 190px;
    padding: 6px 6px;
    background-color: #dedede;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius: 50%;


   
   
}


.team-item:hover{
    background: rgba(98, 139, 201, 0.644);
    
    color: white;
}
.team-item h3{
    margin: 20px 0 1px 0 !important;
    color: #454545;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
.section-subtitle{
    white-space: pre-line;
}
.team-item:hover h3{
    color: black;
}*/
.team-info p {
  color: #3d3939;
  font-style: italic;
}
.team-info::after {
  background: white;

  background: -webkit-linear-gradient(135deg, #62cc53 0%, #63ec5f 100%);
  background: -o-linear-gradient(135deg, #3ab81b 0%, #23a31f 100%);
  background: -moz-linear-gradient(135deg, #228022 0%, #3cad07 100%);
  background: linear-gradient(135deg, #66af50 0%, #3ef506 100%);

  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 50px;
  height: 3px;
  margin-top: -20px;
}
ul.team-icon {
  margin-top: 25px;
}
ul.team-icon li {
  display: inline-block;
  margin: 0 6px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

ul.team-icon li a {
  margin: 0;
  display: inline-block;
  padding: 8px 8px;
  width: 34px;
  height: 34px;
  font-size: 18px;
  line-height: 18px;
  background: #fff;
  border-radius: 50%;
  color: #fff;
}
ul.team-icon li a.twitter {
  background-color: #4099ff;
}

ul.team-icon li a.LinkedIn {
  background-color: #375679;
}
ul.team-icon li a.Instagram {
  background-color: rgb(192, 59, 59);
}
ul.team-icon li a.Facebook {
  margin-top: 2px;
  background-color: rgb(18, 161, 197);
}

.team-icon li a:hover {
  color: #130c0c;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.heading-title {
  position: relative;
  color: rgb(47, 58, 58);
  font-size: 50px;
  font-weight: bold;
  font-style: italic;
}
.hrline {
  border-bottom: 3px solid rgb(100, 105, 184);
  width: 450px;
  margin: 1px auto;
}
.hrline2 {
  border-bottom: 3px solid rgb(52, 66, 52);
  width: 170px;
  margin: 0px auto;
}
.title {
  font-size: 1.2rem;
  color: rgb(230, 225, 235);
}

.avatar {
  width: 900px;
  height: 300px;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  object-position: top;
}
/* ---------------------------------video section css */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.2;
}

.content-scrollable {
  overflow-y: auto; /* Enable vertical scroll for content */
}

/* Hide scrollbar for Firefox */
.content-scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Safari */
.content-scrollable {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

@media (max-width: 768px) {
  .avatar {
    width: 400px;
    height: 300px;
    overflow: hidden;
  }
  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    object-position: top;
  }
}
@media (max-width: 480px) {
  .heading-title {
    position: relative;
    color: rgb(47, 58, 58);
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    margin-left: 19px;
  }

  .team-icon {
    margin-right: 40px;
  }

  .team-images {
    margin-left: -17px;
  }

  .hrline {
    border-bottom: 3px solid rgb(100, 105, 184);
    width: 200px;
    margin: 1px auto;
  }
  .avatar {
    width: 343px;
  }
}

@media (max-width: 340px) {
  .avatar {
    width: 212px;
  }
}
