.poster-container{
height: 100vh;
background-attachment: fixed;
/* opacity: calc(.5); */
background-size: cover;
}

.servic-contain{
    margin-top: 100px;
}

.service-description{
    text-align: justify;
}

.Weighbridge-img img{
    width: 110%;
    height: 350px;
    margin-top: 40px;
}


@media (max-width : 450px){
    .section-hight{
        height: 200vh;
    }
    .mobile-container{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .row{
        position: relative;
    }
    .service-img img{
        width: 80% ;
        /* height:240px ; */
        margin: 2px auto ;
        margin-top: 80px;
    }
    .section-hight{
        height: fit-content;
    }
    .servic-contain{
        margin-top: 0px;
    }
    .servic-contain h2{
        position: absolute;
        top: 20px;
    }
    .servic-contain div{
        position: absolute;
        top: 40px;
    }
}

