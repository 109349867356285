.download-button-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
}

  .download-button-div button {
    padding: 0.3rem;
  width: 150px;
  border-radius: 6px;
  background: #1ba083;
  color: #fff;
  border: none;
  transition: all 0.5s ease-in-out;
  margin-bottom: 20px;
  } 

  .download-buttob-div button:hover {
    letter-spacing: 0.1rem;
  }

.wba-video-container{
  margin:20px auto;
  margin:20px 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}
.sub-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.videos{
  border: 1px solid #1ba083;
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
}

.video_frames {
  border-radius: 5px;
  
}

.wba-image-container>img {
  width: 750px;
  height: 380px;
  object-fit: fill;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.image{
  display: flex;
  justify-content: center;
  align-items: center;
}

.wba-content h3 {
  text-align: center;
}

.wba-content{
  margin: 20px 100px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;

}

.wba-paras{
  margin-left: 3rem;

}

.wba-subheading{
  font-weight: 600;
}

.wba-content-div{
  margin-top: 30px;
}

.read-more{
  color: #0d6efd;
  cursor: pointer;
}

.wba-videos{
  margin:20px 100px;
  /* padding: 100px; */
}

/* .video_frames {
  width: 500px;
  margin: 20px ;
  border-radius: 5px;
} */

.video2 {
  right: 0;
}

@media (max-width:480px) {
  .wba-content {
    margin: 20px 40px;
  }

  .download-button-div{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 20px;
  }
}

@media screen and (max-width : 360px){
  .wba-video-container{
    display: flex;
    flex-direction: column;
  }
  .videos > iframe {
    width: 200px;

  }

  .video-containe {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .crusher_image {
    width: 300px;
  }
}

@media screen and (max-width : 750px){
  .wba-video-container{
    display: flex;
    flex-direction: column;
  }
  .videos > iframe {
    width: 400px;
  }

  .wba-content{
    margin: 10px;
  }

  .video-containe {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .crusher_image {
    width: 400px;
  }
}

@media screen  and (max-width : 1200px){
  .videos > video {
    width: 380px;

  }
}

@media screen  and (max-width : 900px){
  .videos > iframe {
    width: 380px;
    height: 270px;
  }
}

@media screen and (max-width: 400px) {
  .wba-video-container{
    display: flex;
    flex-direction: column;
  }
  .videos > iframe {
    width: 300px;

  }

  .video-containe {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .crusher_image {
    width: 320px;
  }
}
.crusher_image{
  width: 800px;
  /* box-shadow: grey; */
  border: 3px solid gray;
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.55), 25px 0 20px -20px rgba(0, 0, 0, 0.55);

}


