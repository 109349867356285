.CardForHowWeWork-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
  flex-wrap: wrap;
  width: 98%;
  padding: 5px 1rem;
}
.modal-opening-card {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CardForHowWeWork-main-cardDiv {
  width: 32%;
  min-height: 50vh;
  margin-top: 2rem;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 1px #dadada;
  text-align: center;
}
.main-cardDiv-img {
  font-size: 6rem;
  padding-left: 0.1rem;
  margin: 1rem auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.main-cardDiv-img img {
  margin-bottom: 2rem;
}
.cream-green {
  background: #ff0066;
}
.blue {
  background: #3366cc;
}
.purple {
  background: #cc99ff;
}
.Lime {
  background: #33cc33;
}
.violet-milta {
  background: #99ccff;
}
.orange {
  background: #ff9933;
}
.main-cardDiv-content {
  width: 100%;
}
.main-cardDiv-para {
  padding: 1rem 1.4rem;
  text-align: justify;
}
.main-cardDiv-button {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.main-cardDiv-button button {
  padding: 0.8rem 2.8rem;
  border-radius: 5px;
  color: #fff;
  background-color: #3e8804;
  border: none;
  box-shadow: 1px 1px #3e8804;
  font-weight: 500;
}
.main-cardDiv-button button:hover {
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  box-shadow: 1px 1px 10px 1px #3e8804;
}

@media screen and (max-width: 768px) {
  .CardForHowWeWork-main-cardDiv {
    width: 48%;
    min-height: 80vh;
  }
}
@media screen and (max-width: 480px) {
  .CardForHowWeWork-main {
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 0;
    width: 100%;
    padding: 5px 0.2rem;
  }
  .CardForHowWeWork-main-cardDiv {
    width: 96%;
    min-height: 100%;
  }
  .main-cardDiv-para {
    padding: 1rem 0.8rem;
    text-align: justify;
  }
}
