/* *::selection {
  background-color: #3e8804;
  color: #fff;
} */
.hwwHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  margin-left: 3rem;
  color: #636262;
  height: 80vh;
}
.left-hwwHeader {
  width: 80%;
}
.left-hwwHeader-h1 {
  /* color: #3e8804; */
  color: #000;
}
.left-hwwHeader-h2 {
  padding-top: 0.2rem;
  padding-bottom: 2px;
  width: 70%;
  color: #000;
  /* border-bottom: 1px solid #3e8804; */
  border-bottom: 1px solid #000;
}
.left-hwwHeader-p {
  padding-top: 1rem;
  font-size: 1rem;
  text-align: justify;
}
.right-hwwHeader {
  width: 70%;
  display: flex;
  justify-content: center;
}
.right-hwwHeader-img {
  width: 70%;
  height: 50%;
  border-radius: 5px;
  box-shadow: 50px 50px 0px 10px #69c026;
}

.mid-hww {
  margin-top: 2rem;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 4rem;
  background-color: whitesmoke;
  color: #636262;
}
.mid-left {
  width: 70%;
}
.mid-hww-h2 {
  width: 40%;
  color: #3e8804;
  border-bottom: 1px solid #3e8804;
}
.mid-hww-p {
  padding-top: 1rem;
  text-align: justify;
  width: 86%;
}
.mid-right {
  width: 40%;
}
.mid-right img {
  width: 80%;
}
.hww-card-section {
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.hww-card-section-h1 {
  display: flex;
  justify-content: center;
  padding-top: 1.3rem;
}
.hww-card-section h1 {
  border-bottom: 1px solid #3e8804;
}

@media screen and (max-width: 768px) {
  .hwwHeader {
    margin-left: 2rem;
    height: 200vh;
  }

  .left-hwwHeader {
    width: 90%;
  }
  .left-hwwHeader-h1 {
    color: #3e8804;
    margin-bottom: 0;
  }
  .left-hwwHeader-h2 {
    padding-top: 0.1rem;
    margin-bottom: 0;
    width: 98%;
  }
  .left-hwwHeader-p {
    padding-top: 0.2rem;
  }
  .mid-hww {
    height: 110vh;
  }
  .mid-left {
    width: 60%;
  }
  .mid-hww-h2 {
    width: 68%;
  }
  .mid-hww-p {
    padding-top: 0;
    width: 94%;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 480px) {
  .hwwHeader {
    height: 100%;
    margin: 2rem 0.2rem 0 0.5rem;
    flex-direction: column;
    margin-top: 3rem;

    width: 100%;


  }
  .left-hwwHeader {
    width: 96%;
  }

  .left-hwwHeader-h1 {
    margin-bottom: 0.6rem;
  }
  .left-hwwHeader-h2 {
    font-size: 1.2rem;
    width: 100%;
  }
  .left-hwwHeader-p {
    padding-left: 0.2rem;
    padding-right: 0.3rem;
  }
  .right-hwwHeader {
    width: 100%;
    align-items: center;
  }
  .right-hwwHeader-img {
    display: none;
  }
  .mid-hww {
    margin-top: 2rem;
    flex-direction: column;
    padding-left: 0;
    height: 700px;
  }
  .mid-left {
    width: 90%;
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
  .mid-hww-h2 {
    font-size: 1.3rem;
    width: 96%;
    margin-bottom: 1rem;

  }
  .mid-hww-p {
    padding-top: 0;
    width: 100%;
    margin-bottom: 0;
    margin-left: -8px;
  }
  .mid-right {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }
  .mid-right img {
    width: 80%;
  }
  .hww-card-section-h1 {
    padding-top: 2rem;
  }
  .hww-card-section h1 {
    font-size: 1.5rem;
  }
}
