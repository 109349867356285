.contact-contentOnHome {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  align-items: center;
}
.contact-img-home {
  max-width: 80%;
  max-height: 100%;
  border-radius: 18px;
  margin-top: 60px;
  margin-left: 100px;
  overflow: hidden;
}

.contact-contentOnHome .leftcontentOnHome {
  width: 50%;
}

.contact-contentOnHome .leftcontentOnHome h2 {
  font-size: 38px;
  font-weight: 600;
  padding: 10px 30px;
}

.contact-contentOnHome .leftcontentOnHome p {
  padding: 10px 30px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
}

.contact-contentOnHome .rightcontentOnHome {
  width: 50%;
}

.contact-buttonOnHome {
  width: 30%;
  margin: 0 32px;
}

.contact-buttonOnHome button {
  padding: 10px;
  width: 100%;
  background-color: #1ba083;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  border: none;
}

.contact-buttonOnHome button:hover {
  background-color: #49caae;
  /* font-weight: 700; */
  color: #fff;
}

.contact-contentOnHome .rightcontentOnHome h2 {
  display: none;
}

@media (max-width: 576px) {
  .contact-contentOnHome .leftcontentOnHome {
    width: 100%;
  }

  .contact-contentOnHome .rightcontentOnHome {
    width: 100%;
  }

  .contact-contentOnHome .rightcontentOnHome img {
    /* padding: 20px 20px;
      margin: 0 auto;
      /* width: 400px; */
    /* height: 350px; */
    max-width: 95%;
    max-height: 100%;
    border-radius: 18px;
    margin-top: 60px;
    margin-left: 10px;
    overflow: hidden;
  }

  .contact-buttonOnHome {
    width: 50%;
    margin: 10px 0;
  }

  .contact-contentOnHome .leftcontentOnHome p {
    padding: 10px 0;
    text-align: left;
    text-align: justify;
    margin-top: 20px;
  }

  .contact-contentOnHome .leftcontentOnHome h2 {
    /* padding: 10px 0;
  text-align: left;
  text-align: justify; */
    display: none;
  }

  .contact-contentOnHome .rightcontentOnHome h2 {
    padding: 0;
    position: relative;
    top: 30px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    display: block;
  }

  .contact-contentOnHome {
    flex-direction: column-reverse;
  }
}
