.client-section {
  width: 100vw;
  height: 40vh;
  color: #000;
  position: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 30px;
}

.wrapper {
  width: 1500px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Text {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #02203c;
}

.sub-heading {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 40px;
  color: #7c8e9a;
}

.rfm-marquee-container {

  /* display: flex; */
  overflow: hidden;
  user-select: none;
}

.Image-Group {
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 40);
}

.images {
  object-fit: contain;
  height: 200px;
  width: 100%;
  /* height: 220px; */
  border-radius: 0.5rem;
  aspect-ratio: 2/2;
  mix-blend-mode: color-burn;
  padding: 5px 20px;
}

@media screen and (max-width: 480px) {
  .client-section {
    width: 100vw;
    height: 40vh;
    color: #000;
    position: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .Text {
    font-size: 21px;
    font-weight: 700;
    margin-top: 8rem;
    margin-bottom: 0px;
    color: #02203c;
  }

  .sub-heading {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .wrapper {
    margin-bottom: 0rem;
  }

  .marquee-container{
    height: 26vh;
    margin-bottom: 5rem;
  }
}