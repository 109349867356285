.hero-main {
    position: relative;
    width: 100%;
    /* background-image:  linear-gradient( to top right,rgba(0,0,0,0.8),rgba(0,0,0,0.5)); */
    background-image: url('./images/wbridge_bg1.jpg');
    background-size: cover;
    background-position: center;
    /* background-color: gray; */
    height: 70vh;
    /* -wekit-clip-path:polygon(0 0, 100% 0, 100% 80%, 0 100%); */
}

.hero-text-box {
    position: absolute;
    width: 1140px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 300;
    color: white;
    font-weight: 300;
    font-size: 40px;

    /* &>h1 {
        text-align: center;
        text-transform: uppercase;
        padding: 0 7px;
    }

}

@media screen and (max-width:1140px) {
    .hero-text-box {
        width: 100%;
        font-size: 25px;
    }

}

@media screen and (max-width:800px) {
    .hero-text-box {
        margin-left: 15px;
        width: 90%;
        font-size: 20px;
        & > h1 {
            font-size: 1rem;
        }
    }

    .btn {
        display: block;
        margin-top: 20px;

    }

    .btn-take {

        width: 18vw;
    }

    .btn {
        width: 30vw;
    }

}

.section_globe {
    /* height: 75vh; */
    /* position: relative; */
    width: 100vw;
    padding: 30px;
}


.col_1_of_2 {
    width: 50%;
    float: left;
    padding-left: 2rem;
    overflow: hidden;

}

.main_head {
    margin: 40px 0px;
    margin-bottom: 70px;

}

.globe_head {
    margin: 2rem 0;
    text-align: center;
    font-size: 30px;
    background-image: linear-gradient(to right, #F4D03F);
    /* -wekit-background-clip:text; */
    /* /color: aqua;/ */

}

.sub_head {
    text-transform: uppercase;
    font-size: 20px;
    color: #333232;

}

.composition {
    /* background-color: red; */
    position: relative;

}

.composition_pohoto {
    position: absolute;
    width: 50%;
    z-index: 10;
    box-shadow: 0 50px 40px rgba(0, 0, 0, 0.4);
    /* /border-radius: 3px;/ */
    opacity: 1;
    transition-duration: 0.4s;
    /* /outline-color:aqua;/ */
    /* outline: 15px solid  black;*/
    outline-offset: 20px;
}

.composition_pohoto_1 {
    left: 40px;
    top: 40px;
}

.composition_pohoto_2 {
    right: 0;
    top: 150px;
    left: 180px;


}

.composition_pohoto_3 {
    left: 0%;
    right: 10px;
    top: 280px;
}

.composition_pohoto:hover {
    transform: scale(1.05)translateY(5px);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
    z-index: 20;
    outline: 15px solid #F4D03F;
}

.composition:hover .composition_pohoto:not(:hover) {
    transform: scale(0.95);
    opacity: 0;
}

.left_para {
    width: 85%;
    color: #6f6565;
    text-align: left;
}

.btn-anchor {
    background-color: orange;
    color: white;
}

@media screen and (max-width:800px) {
    .composition_pohoto {
        position: relative;
        width: 100%;
    }

    .composition_pohoto {
        left: 0;
        top: 60px;
    }

    .composition{
        display: none;

    }
    .col_1_of_2{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}


.sub_container{
    /* width: 90vw; */
    margin: 20px;
}

.sub_container .sub_head .para {
    width: 90vw;
}

.marb{
    margin-bottom: 100px;

}

@media screen and (max-width:480px){
    .globe_head{
        font-size: 20px;
    }

    .sub_head{
        font-size: 15px;
    }
}