.image-main2-div-career {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-repeat: no-repeat; */
  /* background-position: 100%;
  background-size: 600px 1000px; */
  box-sizing: border-box;
  font-size: 18px;
  margin: 18vh 20px;
}
.poster-div-career {
  width: 50%;
  margin-left: 3vw;
}
.job-cards {
  margin: 20px;
}
.heading-d-career {
  margin-bottom: 6vh;
}
.image-career-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.image-career-div img {
  object-fit: cover;
  width: 85%;
}
.top-jobopenings-notavail {
  margin-left: 10vw;
  margin-bottom: 5vh;
}
.btn-41 {
  background-color: #6eaf10;
  border: 2px solid #6eaf10;
  color: #fff;
}
.btn41-43 {
  cursor: pointer;
  font-weight: 500;
  padding: 8px 50px;
}

@media screen and (max-width: 480px) {
  .image-main2-div-career {
    flex-wrap: wrap;
    margin: 10vh 20px;
  }
  .top-jobopenings-notavail {
    margin-left: 0vw;
    margin-bottom: 10vh;
  }
  .poster-div-career {
    width: 100%;
  }
  .image-career-div {
    margin-top: 8vh;
    width: 100%;
  }
  .image-career-div img {
    width: 100%;
  }
}
