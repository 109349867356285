*{
  margin: 0;
  padding: 0;

}
.heading{
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 50px ;
  text-align: center;
  /* background-color: antiquewhite; */
  color: #6eaf10;
}
.he-1{
  font-weight: bold !important;

}
a{
  text-decoration: none;
}
.a{
  text-decoration: none;
  color: black;
}

.single-line{
  width: 30%;
  border-bottom: 1px solid green;
  margin: 15px 0;
}

.section-hight{
  height: 100vh;
}
body{
  background-color: #fff !important ;
  font-family:'DM Sans', sans-serif !important;
}

.span-line{
  min-width: 100px;

  height: 3px;


}
.mar-30{
  margin-bottom: -30px;
}
@media screen and (max-width:768px) {
  .span-line{
    min-width: 50px;
    height: 4px;
  }

}