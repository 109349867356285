.vision-mission-home {
  margin: 2rem 2rem;
  width: 95%;
}
.vision-home {
  width: 50%;
  display: flex;
  height: 15rem; 
  align-items: center;
  /* margin-left: 2rem; */
  padding: 5px 10px 1px 10px;
  background-image: linear-gradient(45deg, #8dd6f3 0%, #1582ad 100%);
  /* background-color: #12e8cfe2; */
  font-family: Helvetica;
  clip-path: polygon(80% 0, 100% 50%, 80% 100%, 0 100%, 0 0);
  border-radius: 10px;
  color: #fff;
  z-index: 1;
}

.mission-home {
  color: #fff;
  position: relative;
  height: 15rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: linear-gradient(45deg, #1c9ba0 0%, #6bcfd2 100%);
  /* margin-right: 3rem; */
  margin-left: 50%;
  padding: 5px 0 1px;
  clip-path: polygon(20% 0, 0 50%, 20% 100%, 100% 100%, 100% 0);
  overflow: hidden;
  border-radius: 10px;
  z-index: 1;
}

.main-vision,
.main-mission {
  width: 97.7%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.vision-line {
  position: absolute;
  width: 100%;
  left: 49%;
  z-index: 0;
  height: 0.2rem;
  background-image: linear-gradient(90deg, #1582ad 20%, #8dd6f3 100%);
}
.mission-line {
  position: absolute;
  width: 100%;
  height: 0.2rem;
  background-image: linear-gradient(90deg, #6bcfd2 20%, #1c9ba0 100%);
}

.vision-home-imgdiv,
.mission-home-imgdiv {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.vision-home-imgdiv img,
.mission-home-imgdiv img {
  width: 120px;
}
.content {
  width: 60%;
}
.para-mission-home {
  width: 100%;
  text-align: right;
}
.para-vision-home {
  width: 90%;
  text-align: left;
}
.heading-mission-home {
  text-align: right;
}

@media (max-width:480px) {
  .vision-home-imgdiv{
    /* display: none; */
    position: absolute;
    margin: 0;
    padding: 0;
    top: 20px;
    right: 50px;
  }
  .vision-home-imgdiv img{
    width: 50px;
  }
  .para-vision-home{
    height: 150px;
    width: 100%;
    font-size: 14px;
  }

  .content{
    width: 100%;
  }
  .vision-home{
    width: 87%;
    margin: 15px 0;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 0 0);
  }


  .mission-home-imgdiv{
    /* display: none; */
    position: absolute;
    margin: 0;
    padding: 0;
    top: 2px;
    left: 15px;
  }
  .mission-home-imgdiv img{
    width: 50px;
  }
  .heading-mission-home{
    padding-right: 20px;

  }
  .para-mission-home{
    height: 150px;
    width: 100%;
    font-size: 14px;
    padding-right: 20px;
    padding-left: 6px;
  }
  .mission-home{
    width: 87%;
    margin: 15px 0;
  clip-path: polygon(0% 0, 0 50%, 0% 100%, 100% 100%, 100% 0);

  }

  .mission-line {
    /* position: absolute;
    width: 100%;
    height: 0.2rem;
    background-image: linear-gradient(90deg, #6bcfd2 20%, #1c9ba0 100%); */
    display: none;
  }

  .vision-line {
    display: none;
  }
}