
.contact-container{
  padding: 100px 20px;
  color: black;
  background-size: 100% 100%;
  background-image: url('./../../assets/green-background.jpg') ;
  /* background-image: url('https://img.freepik.com/free-vector/green-curve-abstract-background_53876-99569.jpg?t=st=1709386953~exp=1709390553~hmac=4c880f8410cbaf2c83f5f92754746f5343564da62eb9dd739dffad4c0ee5052b&w=996'); */
  /* background-image: url('https://files.123freevectors.com/wp-content/original/116446-abstract-dark-green-defocused-background-image.jpg'); */
 
}

.contact-us-heading{
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-contact-us {
  margin-top: 2rem;
}

.contact-heding{
  font-weight: bold;
  font-size: 45px;
}
.map-container {
  height: 200px;
  position: relative;
}

.map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.container-form{
  padding: 30px;
}

.drow-shadow{
  /* filter: drop-shadow(2px 2px 20px 10px black) !important; */
  /* box-shadow: 2px 2px 20px 2px black; */
  filter: drop-shadow(30px 2px 10px 0px #4444dd);
  border-bottom: 1px solid green;
}

.col-twelve{
    width: fit-content;
    padding: 20px;
    /* background-color: aqua; */
    margin: auto auto;
}
.form-field{
    /* background-color: antiquewhite;  */
    

    padding:10px 20px;
}
.input-box{
    width: 315px;
    padding: 9px 20px;
   
}
.message-box{
    padding: 10px;
    font-size: 17px;
    columns: 40;
}
.form-container{
  background-color: rgba(45, 157, 73, 0.108);
  padding: 30px;
  border: 1px solid rgb(230, 232, 230);
  /* border-radius: 10px; */
  box-shadow: 0 2px 20px 0 rgb(179, 178, 178);
 
 
}

/* button */

.bn4 {
  background-color: #86d472;
  border: none;
  color: rgb(0, 0, 0);
  padding: 0.6em 2.7em;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  /* border: 1px solid black; */
}

.bn4:hover {
  opacity: 0.6;
  
}

 

  .details-container{
    display: flex;
    justify-content: space-around;
    margin: 100px 10px;
  }
 
  .col-green{
    color: #86d472;
  }
  .bg-green{
    background-color: #86d472;
    color: white;
  }
  .bg-green:hover{
    background-color: #2c6c1d;
    color: white;
  }
  .m-0{
    margin: 0;
  }

  .parent_container{
    margin-top: -22px;
  }
 
  .sub-d-container{
    width: 500px;
    border: 1px dashed black;
    padding: 12px;
    margin: 23px;
  }

  @media screen and  (max-width:768px) {

    .parent_container {
      margin-top: 10px;
    }

    .form-container{
      background-color: rgba(45, 157, 73, 0.108);
      padding: 10px;
      border: 1px solid rgb(230, 232, 230);
      /* border-radius: 10px; */
      box-shadow: 0 2px 20px 0 rgb(179, 178, 178);
      width: 320px;
    }
    .input-box{
      width: 250px;
      padding: 9px 20px;
     
  }
  .details-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 50px 10px;
  }
  .sub-d-container{
    width: 300px;
    border: 1px dashed black;
    padding: 20px;
    margin: 20px 10px;
  }

  .d-grid > button {
    width : 180px;
    margin-left : 0px
  }
  }

 