.Navbar2 {
  height: fit-content;
}
.responsive-dropdown {
  display: none;
}
.margin-start {
  margin-left: 300px;
}
.Logo {
  height: 70px;
  width: 200px;
  filter: drop-shadow(5px 5px 15px 15px rgb(255, 252, 252));
}

.droupdown-link:hover {
  background-color: rgb(233, 236, 238);
}
.nav-drop-down {
  font-size: 20px;
}

@media (max-width: 990px) {
  .responsive-dropdown1 {
    display: none;
  }
  .responsive-dropdown {
    display: block;
  }
  .margin-start {
    margin-left: 0px;
  }
}
