.download-button-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
}

.download-button-div button {
  padding: 0.3rem;
  width: 150px;
  border-radius: 6px;
  background: #1ba083;
  color: #fff;
  border: none;
  transition: all 0.5s ease-in-out;
  margin-bottom: 20px;
}

.download-button-div button:hover {
  letter-spacing: 0.1rem;
}

.wba-video-container {
  margin: 20px auto;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videos {
  border: 1px solid #1ba083;
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
}

.video_frames {
  border-radius: 5px;
}

.wba-image-container > img {
  width: 750px;
  height: 380px;
  object-fit: fill;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wba-content h3 {
  text-align: center;
}

.wba-content {
  width: 90vw;
  border: none;
  margin: 20px 60px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  text-align: left;
}

.wba-paras {
  margin-left: 3rem;
}

.wba-subheading {
  font-weight: 600;
}

.wba-content-div {
  margin-top: 30px;
}

.read-more {
  color: #0d6efd;
  cursor: pointer;
}

.wba-videos {
  margin: 20px 100px;
}

/* Media queries */

 @media screen and (max-width: 800px) {
  .wba-video-container {
    flex-direction: column;
  }

  .wba-content {
    margin-left: 17px;
  }

  .videos > iframe {
    width: 410px;
    height: 270px;
  }

  .wba-image-container > img {
    width: 400px;
  }

  .image img {
    width: 440px;
  }
} 


@media screen and (max-width: 400px) {
  .wba-video-container {
    flex-direction: column;
    margin-left: 18px;
  }

  .videos {
    margin-left: -7px;
  }

  .wba-para {
    margin-left: 100px;
  }

  .download-button-div {
    flex-direction: column;
    gap: 0.5rem;
  }

  .wba-paras {
    margin-left: 35px;
  }

  .videos > .video_frames {
    width: 300px;  
    height : 200px
  }

  .wba-heading {
    font-size: 28px;
    font-weight: 400;
  }

  .image img {
    width: 340px;
  }

  .wba-para {
    width: 100%;
    margin-left: 10px;
    justify-content: center;
  }
}


/* @media screen and (max-width: 460px) {
  .wba-video-container {
    flex-direction: column;
    margin-left: 18px;
  }

  .videos {
    margin-left: -7px;
  }

  .wba-para {
    margin-left: 100px;
  }

  .download-button-div {
    flex-direction: column;
    gap: 0.5rem;
  }

  .wba-paras {
    margin-left: 35px;
  }

  .videos > .video_frames {
    width: 50px;  
  }

  .wba-heading {
    font-size: 28px;
    font-weight: 400;
  }

  .image img {
    width: 340px;
  }

  .wba-para {
    width: 100%;
    margin-left: 10px;
    justify-content: center;
  }
}  */







 @media screen and (max-width: 1200px) {
  .videos {
    margin-left: -7px;
  }

  .wba-para {
    margin-left: 100px;
  }

  .download-button-div {
    flex-direction: column;
    gap: 0.5rem;
  }

  .wba-paras {
    margin-left: 35px;
  }

  .videos > iframe {
    width: 400px;
  }

  .wba-heading {
    font-size: 28px;
    font-weight: 400;
  }

  .image img {
    width: 60vw;
  }

  .wba-para {
    width: 100%;
    margin-left: 10px;
    justify-content: center;
  }
} 
