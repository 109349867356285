.about-philosophy-container {
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-philosophy {
  margin: 0 auto;
  max-width: 800px;
}

.our-philosophy-container {
  text-align: center;
}

.our-philosophy-heading {
  font-size: 38px;
  font-weight: 600;
  font-family: Arial, sans-serif;
  margin-bottom: 20px;
}

.philosophy-section {
  margin-bottom: 40px;
  text-align: center;
}

.philosophypara {
  font-size: 18px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
}

.CardForPhilosophy-main-cardDiv {
  width: 32%;
  min-height: 60vh;
  margin-top: 2rem;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 1px #dadada;
  text-align: center;
}

.main-Philosophy-cardDiv-img {
  font-size: 4.5rem;
  padding: 0.1rem 0 1rem 0.5rem;
  margin: 1rem auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.pink {
  background: #ff3399;
}

.blue {
  background: #3366cc;
}

.purple {
  background: #cc99ff;
}

.main-Philosophy-cardDiv-content {
  width: 100%;
}

.main-Philosophy-cardDiv-para {
  padding: 1rem 1.4rem;
  text-align: justify;
  font-size: 17px;
}

.CardForPhilosophy-main {
  margin-top: -3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6rem;
  flex-wrap: wrap;
  width: 88%;
  padding: 5px 1rem;
}

/* Media Queries for iPad */
@media only screen and (max-width: 1024px) {
  .about-philosophy {
    max-width: 90%;
    /* Adjust maximum width for iPad screens */
  }
}

/* Media Queries for Mobile Screens */
@media only screen and (max-width: 768px) {
  .CardForPhilosophy-main-cardDiv {
    width: 65%;
    min-height: 40vh;
    margin-left: 55px;
  }
}

@media only screen and (max-width: 480px) {
  .CardForPhilosophy-main {
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -47px;
    width: 120%;
    padding: 5px 0.01rem;
    margin-right: 200px;
  }

  .CardForPhilosophy-main-cardDiv {
    width: 96%;
    min-height: 100%;
    margin-right: 25px;
    width: 200%;

  }

  .main-Philosophy-cardDiv-para {
    padding: 1rem 0.8rem;
    text-align: justify;
  }
}

.CardForPhilosophy-main-cardDiv:hover{  
  transform:scale(1.0) translateY(-5px);
  box-shadow: 0 25px 40px rgba(0,0,0,0.5);
}