.content-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
}

.left-content {
  flex: 1;
}

.blue-heading {
  margin-top: 30px;
  font-size: 2.7em;
  /* color: #6eaf10; */
  font-weight: 700;
  margin-left: 17px;
}

.left-para {
  text-align: justify;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 1.1em;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
}

.right-content {
  margin-top: 25px;
  flex: 1;
  text-align: center;
}

.cofounder-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.color-head {
  color: #6eaf10;

  font-weight: 600;
}

.img-cofounder {
  max-width: 100%;
  /* max-height: 1150%; */
  max-height: 100%;
  /* height: auto; */
  border-radius: 18px;
  margin-top: 60px;
  margin-left: 100px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: -27px;
  }

  .right-content {
    order: -1;

    /* margin-top: 0; */
    margin-right: 40px;
  }

  .img-cofounder {
    margin-left: 30px;

    /* Reset margin for smaller screens */
  }
}

/* Responsive styles for iPhone 6, 7, 8 (375x667) */
@media (max-width: 527px) {



  .blue-heading {
    font-size: 2.2em;
  }

  .left-para {
    font-size: 1em;
  }

  .cofounder-heading {
    font-size: 1.2em;
  }

  .img-cofounder {
    max-width: 100%;
    margin-left: 30px;
    /* margin-right: 30px; */
    /* Reset margin for smaller screens */
  }
}
