.hero-container {
    background: #0c0c0c61;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 700px;
    position: relative;
    overflow: hidden;
}

.hero-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.hero-content {
    z-index: 1;
    text-align: center;
}

.hero-content h1 {
    color: #fff;
    font-size: 48px;
    margin-bottom: 24px;
}

.hero-content p {
    color: #fff;
    font-size: 24px;
    margin-bottom: 32px;
}

.hero-content button {
    padding: 12px 30px;
    background: #1ba083;
    color: #fff;
    font-size: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.hero-content button:hover {
    background: #fff;
    color: #01bf71;
    transition: all 0.2s ease-in-out;
}
