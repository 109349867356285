.swiper {
  width: 100%;
  height: 100%;
}

.section {
  margin-left: 8rem;
  margin-right: 8rem;
}
.common-heading {
  font-family: Italic;
  font-weight: 600;
  font-size: 2.5rem;
}
.gradient-underline {
  background-image: linear-gradient(to right, #ff0000, #00ff00);
  background-size: 30% 2px;
  background-position: 0 bottom;
  background-repeat: no-repeat;
}

.swiper-slide {
  text-align: left;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-client-msg {
  padding: 1rem 2rem;
  background: white;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 5px 5px 5px rgb(197, 194, 194);
  position: relative;
  color: rgb(79, 121, 106);
  height: 180px;
}
.swiper-slide img {
  max-width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 0.5rem solid beige;
}
.swiper-client-msg::before {
  content: "";
  position: absolute;
  bottom: -10rem;
  left: 50%;
  transform: translateX(-50%);
  border: 5rem solid white;
  border-color: transparent;
  border-top-color: rgb(236, 239, 240);
}
.swiper-client-msg p::before {
  content: "\f10d";
  position: relative;
  top: 0;
  left: 0rem;
  font-size: 3rem;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #ced3ff;
  margin-right: 2rem;
}
.swiper-client-msg p::after {
  content: "\f10e";
  position: absolute;
  bottom: -0.8rem;
  right: 1rem;
  font-size: 3rem;
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  color: #ced3ff;
  /* margin-right: 1rem; */
}
.swiper-client-data {
  display: flex;
  justify-content: center;
  gap: 10rem;
  align-items: center;
  margin-top: 2.7rem;
  gap: 3rem;
}

.client-data-details {
  z-index: 1;
  margin-bottom: 0px;
  color: gray;
  margin-bottom: 2rem;
}
.client-name {
  margin-bottom: 0rem;
}
.last-client-name {
  margin-top: 1.5rem;
}

/* ----------------------Media querie----------------------- */

@media screen and (max-width: 1200px) {
  .section {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

@media screen and (max-width: 992px) {
  .section {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .section {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
/* Media queries */
@media screen and (max-width: 576px) {
  .section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .common-heading {
    font-size: 2rem;
  }

  .swiper-client-msg {
    padding: 0.5rem 1rem;
    font-size: 14px;
  }

  .swiper-slide {
    margin-bottom: 2rem;
  }

  .swiper-slide img {
    max-width: 5rem;
    height: 5rem;
  }
}

@media screen and (max-width: 480px) {
  .section {
    margin-bottom: 7px;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }

  .common-heading {
    font-size: 1.8rem;
  }

  .swiper-client-msg {
    padding: 0.3rem 0.8rem;
    font-size: 12px;
    margin: 10px;
  }

  .swiper-slide img {
    max-width: 4rem;
    height: 4rem;
  }
  .swiper-client-data img {
    max-width: 30px;
    height: 30px;
    border: none;
  }
  .swiper-client-msg p::before {
    content: "\f10d";
    position: relative;
    top: 0;
    left: 0rem;
    font-size: 2rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #ced3ff;
    margin-right: 2rem;
    aspect-ratio: 16/16;
  }
  .swiper-client-msg p::after {
    content: "\f10e";
    position: absolute;
    bottom: -0.8rem;
    right: 1rem;
    font-size: 2rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 700;
    color: #ced3ff;
    /* margin-right: 1rem; */
  }
  .swiper-client-msg::before {
    content: "";
    position: absolute;
    bottom: -6rem;
    left: 50%;
    transform: translateX(-50%);
    border: 3rem solid white;
    border-color: transparent;
    border-top-color: rgb(236, 239, 240);
  }

  .client-data-details {
    z-index: 1;
    color: gray;
    margin-bottom: -10px;
    font-size: 15px;
  }
  .swiper-client-data {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-items: center;
    margin-top: 2.7rem;
    /* gap: 3rem; */
  }
  .Text {
    font-size: 15px;
  }
}

/* Add more media queries for smaller screen sizes as needed */
