.model-main {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.457);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-main-div {
  width: 40vw;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 3rem;
  margin-top: 10px;
  background: #fff;
}
.model-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d3d3d3e7;
}
.model-header-heading {
  padding: 0.5rem 1rem 0;
  color: #3e8804;
}
.model-header button {
  font-size: 1.5rem;
  border: none;
  background-color: #ffffff00;
  margin-right: 1.5rem;
  transition: all 0.3s;
}
.model-header button:hover {
  cursor: pointer;
  transform: scale(1.5);
  padding: 0;
}
.model-mid-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 3rem;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.model-actualContent {
  padding: 2px 2rem;
  text-align: justify;
}

.model-closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
}
.cream-green {
  background: #ff0066;
}
.blue {
  background: #3366cc;
}
.purple {
  background: #cc99ff;
}
.Lime {
  background: #33cc33;
}
.violet-milta {
  background: #99ccff;
}
.orange {
  background: #ff9933;
}
.model-closeButton button {
  padding: 6px 1.8rem;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #3e8804;
}
.model-closeButton button:hover {
  transition: 0.9s cubic-bezier(0.5, 1, 0.89, 1);
  color: #fff;
  background-color: #3e8804;
}

@media screen and (max-width: 768px) {
  .model-main {
    width: 100%;
  }
  .model-main-div {
    width: 60%;
  }
  .model-closeButton button:focus {
    transition: 0.9s cubic-bezier(0.5, 1, 0.89, 1);
    color: #fff;
    background-color: #3e8804;
  }
  .model-actualContent {
    padding: 2px 1.5rem;
    text-align: justify;
  }
}
@media screen and (max-width: 480px) {
  .model-main {
    width: 100%;
    height: 100%;
  }
  .model-main-div {
    width: 100%;
  }
  .model-closeButton button:focus {
    transition: 0.9s cubic-bezier(0.5, 1, 0.89, 1);
    color: #fff;
    background-color: #3e8804;
  }
  .model-actualContent {
    padding: 2px 1.5rem;
    text-align: justify;
  }
}
