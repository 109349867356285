.about-background {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("img.jpg") center/cover no-repeat;
  filter: blur(2px);
}

.bold-h1 {
  font-weight: 600;
  font-size: 4.5em;
  margin-bottom: 40px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.content-about {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
}

.quote-container {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 1.2em;
}

.quote {
  font-style: italic;
  margin-bottom: 10px;
}

@media (max-width: 569px) {
  .about-background {
    height: 450;
  }

  .bold-h1 {
    font-size: 2.7em;
    /* Adjust as needed for smaller screens */
  }

  .quote-container {
    font-size: 1em;
    /* Adjust as needed for smaller screens */
  }
}
