.service-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  margin-top: 20px !important;
}

.service-data-container {
  max-width: 100%;
}

.ecommerce-heading {
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.image-container {
  width: 100%;
  /* Make the container take up the full width */
  display: flex;
  position: relative;
  justify-content: center;
  /* Center the image horizontally */
  overflow: hidden;
  /* Hide any overflow to maintain aspect ratio */
  height: 100vh;
}

.img-moredetails {
  width: 100%;
  /* Make the image take up the full width of the container */
  height: auto;
  display: block;
  /* Maintain the aspect ratio */
  object-fit: cover;
  max-width: 100%;
  /* Ensure it doesn't exceed the container's width */
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgb(0, 0, 0);
  padding: 10px 80px;
  border-radius: 5px;
  /* display: none; */
  opacity: 0;
  display: flex;
  font-size: 1.9rem;
  line-height: 2.5rem;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  text-align: center;
}

.image-container:hover .hover-text {
  opacity: 1;
}

.image-container:hover .img-moredetails {
  transition: opacity ease-in 0.2s;
  opacity: 0.1;
}

.content-section {
  padding: 2rem 7rem;
  margin: 2rem 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 10px;
  /* border: 2px solid black; */
  -webkit-box-shadow: 23px 25px 51px 5px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 23px 25px 51px 5px rgba(0, 0, 0, 0.58);
  box-shadow: 23px 25px 51px 5px rgba(0, 0, 0, 0.58);
}

.text-body-secondary {
  /* -webkit-box-shadow: 10px 10px 26px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 26px -6px rgba(0,0,0,0.75);
box-shadow: 10px 10px 26px -6px rgba(0,0,0,0.75); */
  /* border: 2px solid black; */
  padding: 2rem 0;
  font-size: 1.3rem;
}

/* .img-moredetails {
  background-repeat:no-repeat;
  background-size: contain;
  width: 100%;
  height: 350px;
} */

/* .contact-1-container-sub {
  width: 300px;
  height: fit-content;
  /* background-color: red; */
/* color: rgb(55, 55, 55); */
/* padding: 10px;
  text-align: center;
  border: 2px dashed white; 
}*/

/*.contact-1-container {
  position: fixed; 
  background-color: green;
  color: white;
  padding: 20px;
  text-align: center;
  height: fit-content;
  box-shadow: 0 2px 10px 0 rgb(59, 59, 59);
} */
.call-icon {
  font-size: 60px;
}

.heding-container {
  padding: 80px 20px !important;
  background-image: url("https://img.freepik.com/free-vector/green-curve-abstract-background_53876-99569.jpg?t=st=1709386953~exp=1709390553~hmac=4c880f8410cbaf2c83f5f92754746f5343564da62eb9dd739dffad4c0ee5052b&w=996");
  background-size: 100% 100%;
  margin-bottom: 50px;
}

/* btn */

.bn53 {
  background-color: #1ba083;
  padding: 7px;
  color: white;
  border: none;
  width: 100px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  animation: bn53bounce 4s infinite;
  cursor: pointer;
}

@keyframes bn53bounce {
  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(1);
  }

  15% {
    transform: scale(1);
  }

  20% {
    transform: scale(1) rotate(-5deg);
  }

  25% {
    transform: scale(1) rotate(5deg);
  }

  30% {
    transform: scale(1) rotate(-3deg);
  }

  35% {
    transform: scale(1) rotate(2deg);
  }

  40% {
    transform: scale(1) rotate(0);
  }
}

@media screen and (max-width: 1370px){
  .image-container {
    width: 100%;
    margin: auto;
    height: 80%;
  }

  .img-moredetails {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .service-container {
    width: 100%;
  }
  .service-data-container {
    max-width: 100%;
  }

  .image-container {
    width: 100%;
    margin: auto;
    height: 80%;
  }

  .img-moredetails {
    width: 100%;
    height: 100%;
  }

  .service-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    margin-top: 50px !important;
  }

  .content-section {
    padding: 0 2rem;
    margin: 0;
  }

  .hover-text {
    font-size: 1.1rem;
    line-height: 20px;
  }
}

@media screen and (max-width: 480px) {
  .image-container {
    width: 100%;
    margin: auto;
    height: 70%;
  }

  .img-moredetails {
    width: 100%;
    height: 100%;
  }
  .hover-text {
    font-size: 0.7rem;
    line-height: 15px;
    padding: 5px;
  }

  .ecommerce-heading {
    font-size: 1.2rem;
  }
  .fw-bold {
    font-size: 1.2rem;
  }

  .text-body-secondary {
    font-size: 1rem;
  }
}

