.icons{
    position: fixed;
    right: 0;
    z-index: 999;
    background-color: #ffffff;
    padding: 10px;
    /* border-bottom: 5px solid #6eaf10; */
    
    border-radius: 0px 0 2px 6px ;

}

.icon-div{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.social-icon{
    width: 32px;
    cursor: pointer;
}
.social-icon:hover{
        transform: scale(1.2);
    transition: 0.2s;
}

@media (max-width : 700px) {
    .icons{
        display: none;
    }
    
}