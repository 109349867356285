.service-heading{
    font-style: italic;
    margin-left: 20px;
    font-weight: 700;
    position: relative;
  
}
.service-heading span{
    color: blue;
}
.service-heading::after {
    margin-left: 30px;
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px; /* Adjust the value to control the distance of the underline from the text */
    width: 20%;
    height: 3px; /* Adjust the thickness of the underline */
    background: linear-gradient(to right, #406319, #352111, #ffde7d); /* Define your linear gradient colors */
  }


.cards{
    height: 200px;
    border-radius: 10px;
    margin: 10px;
}
.card-img-container {
    height: 200px; 
    overflow: hidden; 
  }
.card-img {

    width: 100%;
    height:200px;
    object-fit: cover; 
    filter: brightness(50%);
    /* position: relative; */
    background-position: center;
    
  }

.card-img-overlay{
    color: white;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 5px;
   
}

.card-title{
    font-size: 2rem;
}

.btn-services{
  /* position: relative; */
    color: white;
    font-weight: 600;
     margin-bottom: 20px;
     object-fit: cover;
}
.arrow-icon{
    margin-left: 10px;
    font-size: 1rem;
}
.main{
    margin-left: 30px;
    margin-right: 30px;
}
.know-more {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

/* ================================== trails  */
  /* .card-img-overlay{
    color: white;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 5px;
   
}

.card-title{
    font-size: 2rem;
}

.btn-services{
    color: white;
    font-weight: 600;
}
.arrow-icon{
    margin-left: 10px;
    font-size: 18px;
}

  

   */
   /* .cards {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-img-container {
    height: 200px; /* Set a fixed height for the card image container 
    overflow: hidden; /* Hide overflow to prevent image overflow 
  }
  
  .card-img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Make the image cover the container 
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .card-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  .card-text {
    font-size: 1rem;
  }
  
  .know-more {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  
  .arrow-icon {
    margin-left: 0.5rem;
  } */

  /* For small devices (576px and below) */
@media (max-width: 576px) {
  .card-title {
    font-size: 1.2rem; /* Adjust the font size as needed */
  }
  .card-text {
    font-size: 0.9rem; /* Adjust the font size as needed */
  }
  .btn-services {
    font-size: 0.7rem; 
    padding: 0.5rem 1rem; 
    margin-bottom: 0px;
    text-align: center;
  }

  .arrow-icon{
    display: none;
  }
}

/* For medium devices (768px and below) */
@media (max-width: 768px) {
  .card-title {
    font-size: 1.5rem; /* Adjust the font size as needed */
  }
  .card-text {
    font-size: 1rem; /* Adjust the font size as needed */
  }
  .btn-services {
    font-size: 1rem; /* Adjust the font size as needed */
    padding: 0.6rem 1.2rem; /* Adjust the padding as needed */
  }
}

/* For screens up to 400px width */
@media (max-width: 400px) {
  .card-title {
    font-size: 1.2rem; 
  }
  .card-text {
    font-size: 0.9rem; 
  }
  .btn-services {
    font-size: 0.7rem; 
    padding: 0.5rem 1rem; 
    margin-bottom: 0px;
    text-align: center;
  }

  .arrow-icon{
    display: none;
  }
}


  